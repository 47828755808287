import React from 'react'

const Footer = () => {
  return (
    <div>
        <div className=' mainfont bg-gray-200'>
  <p className=' mainfont text-xl text-black text-center
  '>© 2022-23 BrainBits. All Rights Reserved.</p>
  <div className="w-full bgour6 mainfont  text-center">
          <p className="">brainbitsapp@gmail.com</p>
          {/* <p className="leading-normal my-2 ">KIET Group Of Institution , */}
            {/* <br></br>Ghaziabad  */}
          {/* </p> */}
          <p className="py-1"> Contact : 7061280059,8299550885</p>

          
    </div>
    </div>
    </div>
  )
}

export default Footer;