export const topicList = [
    {
        langheading:  "Challenge Fundamentals"
    },
    {
        langheading:"Challenge Arrays"
    },
    {
        langheading:  "Challenge Strings"
    },
    {
        langheading:  "Challenge Recursion"
    },
    {
        langheading:  "Challenge Backtracking"
    },
    {
        langheading:  "Challenge Stack and Queues"
    },
    {
        langheading:  "Challenge Linked Lists"
    },
    {
        langheading:  "Challenge Trees"
    },   {
        langheading:  "Challenge Heap"
    },   {
        langheading:  "Challenge Hashing and Tries"
    },   {
        langheading:  "Challenge DP"
    },
    {
        langheading:  "Challenge Graph"
    },{
        langheading:  "Challenge Bitmasking"
    },

]
